<template>
    <div>
        <div class="modal fade" ref="modal">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="modalClasses">
                <div class="modal-content">
                    <div class="modal-header p-0">
                        <slot name="header">
                            <h4 class="modal-title">{{ title }}</h4>
                            <button type="button" class="btn-close" @click="closeModal"/>
                        </slot>
                    </div>
                    <div class="modal-body p-0">
                        <slot/>
                    </div>
                    <div class="modal-footer p-0">
                        <slot name="footer">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import Modal from "bootstrap/js/dist/modal"

export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
        },
        size: {
            type: String,
            default: "md",
            description: "sm|md|lg|xl"
        }
    },
    data() {
        return {
            modal: null
        }
    },
    computed: {
        modalClasses() {
            let modalClasses = ""
            if (this.size && ['sm', 'lg', 'xl'].includes(this.size))
                modalClasses += 'modal-' + this.size
            return modalClasses
        }
    },
    methods: {
        closeModal() {
            this.$emit('update:show', false)
            this.$emit('modal:hidden')
        },
        openModal() {
            this.$emit('update:show', true)
            this.$emit('modal:show')
        },
        openModalFinished() {
            this.$emit('modal:shown')
        }
    },
    mounted() {
        this.modal = new Modal(this.$refs.modal)
        this.$refs.modal.addEventListener('hidden.bs.modal', this.closeModal)
        this.$refs.modal.addEventListener('show.bs.modal', this.openModal)
        this.$refs.modal.addEventListener('shown.bs.modal', this.openModalFinished)
    },
    beforeDestroy() {
        this.$refs.modal.removeEventListener('hidden.bs.modal', this.closeModal)
        this.$refs.modal.removeEventListener('show.bs.modal', this.openModal)
        this.$refs.modal.removeEventListener('shown.bs.modal', this.openModalFinished)
        this.modal?.hide()
        this.$emit('update:show', false)
    },
    watch: {
        show: {
            handler(value) {
                if (value) this.modal?.show()
                else this.modal?.hide()
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
